/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import styles from '../styles/termsofUse.module.scss';

const TermsAndConditions = () => (
  <Layout>
    <SEO />
    <div className={styles.banner} />
    <div className={styles.container}>
      <div className={`${styles.pageContainer} container`}>
        <h1 className={styles.title}>
          Terms of Use
        </h1>
        <div className={styles.content}>
          <div className={styles.firstSection}>
            <p className={styles.firstSection}>
              This document is an electronic record in terms of the Information Technology Act, 2000 and the rules framed thereunder. This electronic record is generated by a computer system and does not require any physical or digital signatures.
            </p>
            <br />
            <p className={styles.firstSection}>
              This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of the <span><a href="/" className={styles.webSiteLink}>www.klubworks.com</a></span> website.
            </p>
          </div>
          <br />
          <div className={styles.secndSection}>
            <p>
              Klub Works Pvt. Ltd. (hereinafter referred to as “<span className={styles.firstSection}>We</span>”, “<span className={styles.firstSection}>Us</span>”, “<span className={styles.firstSection}>Klub</span>” or “<span className={styles.firstSection}>Our</span>” which expression shall mean and include its affiliates, successors and permitted assigns).
              Your (“<span className={styles.bold}>You</span>” or “<span className={styles.firstSection}>Your</span>”) use of the Platform (defined below) is subject to the notices, terms and conditions set forth in these Terms of Use. The domain name “<span className={styles.highlight}><a href="/">www.klubworks.com</a></span>” (hereinafter collectively referred to as the “<span className={styles.firstSection}>Platform</span>”) is owned by Klub. Your use of the Platform is governed by the following terms and conditions (“<span className={styles.firstSection}>Terms of Use</span>”) as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. You acknowledge and agree that You shall be subject to the policies that are applicable to the Platform and by mere use of the Platform, You shall be contracting with Klub and these terms and conditions including the policies constitute Your binding obligations, with Klub.
            </p>
            <br />
            <p>
              By mere use of the Platform, You agree to be subject to the applicable rules, guidelines, policies, terms, and conditions and the same shall be deemed to be incorporated into this Terms of Use and be considered as part and parcel of this Terms of Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates/changes. We will notify You of any material updates/changes from time to time. Your continued use of the Platform following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform.
            </p>
          </div>
          <br />
          <p className={styles.firstSection}>
            ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR ACCEPTANCE OF ALL THE TERMS AND CONDITIONS IN THESE TERMS OF USE. PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE PROCEEDING. YOU DECLARE YOUR WILLINGNESS TO ABIDE AND BE BOUND BY THESE TERMS OF USE THROUGH YOUR USE OF THE PLATFORM. IF YOU DO NOT AGREE WITH THE TERMS OF USE, PLEASE DO NOT USE THE PLATFORM. These Terms of Use will be effective immediately upon Your acceptance of Our terms and conditions, Your use of Our Platform being indicative of such acceptance. These Terms of Use shall be enforceable against You in the same manner as any other written agreement.
          </p>
          <div>
            {/* what we do */}
            <h3 className={styles.headingH3}>1. What we do</h3>
            <p className={styles.sectionContent}>
              We provide a platform through which the new and growing businesses and registered users can interact with each other for the purpose of evaluating a potential business relationship We enable users of the Platform to register either as a Brand (defined below) or as a Patron Investor (defined below). Registered users are hereinafter referred to as <span className={styles.bold}>Members</span>. Through the tools and services provided on such platform, We facilitate the validation of the business ideas provided by the Brands as well as effective and secure communication between such Brands and other users including Patron Investors. We may also provide certain offline services including but not limited to conducting seminars, workshops and providing an opportunity to the users of the Platform to interact with other users of the Platform offline.
            </p>

            {/* platform */}
            <h3 className={styles.headingH3}>2. Platform</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>2.1  </span>You can access the Platform without registering for an account. However, to use several features, You will need to register and create a profile with accurate and complete information. Your profile must be your own or of a person or entity You are entitled to represent and You shall not use or provide any information that is offensive or that violates any third party’s rights.
              </p>
              <br />
              <p>
                <span className={styles.bold}>2.2 Account de-activation:   </span>You can choose to deactivate Your account with Klub maintained on the Platform by notifying <a href="mailto:partner@klubworks.com">partner@klubworks.com</a>. Please note that any cancellation/deactivation of Your account shall be subject to the payment terms applicable to You.
              </p>
            </div>

            {/* Brand */}
            <h3 className={styles.headingH3}>3. Brand</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>3.1 Sign-up: </span>If You are a business looking for advice, mentors, prospective employees or to connect with the Patron Investor community, You have to register as a ‘Brand’ (<strong>Brand</strong>). Depending on Your requirements/features you wish to access, You shall provide basic or detailed profile data. We may provide You with features where you can opt to restrict the visibility of specified parts of Your profile to the public or other Members of the Platform. We strive to restrict the visibility of these aspects only to those users that have represented they fit within the categories You have identified. We cannot, however, guarantee that users who access this restricted information will not distribute that information to other persons.
              </p>
              <br />
              <p>
                <span className={styles.bold}>3.2 Features: </span>Klub provides You with various features on the Platform. Each of these features enables different forms of interactions with other users of the Platform. You may be required to accept special terms and conditions, execute additional documentation or pay additional fees to access some of these features. When You opt for certain features, based on Your authorization, We may be required to reach out to Patron Investors relevant to You using the built-in recommendation engine algorithms or based on our non-automated database.
              </p>
              <br />
              <p>
                <span className={styles.bold}>3.3 Funding: </span>You acknowledge that You are not a publicly listed company and Your securities are not registered, or required to be registered, on any stock exchange in any jurisdiction. In the event You and any other Member of the Platform choose to engage in a business relationship, whether or not a proposed investment into the Brand, the terms and conditions of such relationship shall be agreed between the parties to such relationship and Klub shall not be responsible for the same. Any transaction in securities that You may offer or conclude with any other Member of the Platform shall be offered, issued, allotted or transferred in strict compliance of all applicable laws including but not limited to private placement rules under applicable securities laws. Our Platform has an internal mechanism to restrict the number of Patron Investors that view the detailed profile to 200 by default thereby making it compliant with the applicable laws. However, it shall be Your responsibility as a Brand to comply with the provisions of applicable laws including the Companies Act, 2013 and the private placement rules thereunder.
              </p>
              <br />
              <p>
                <span className={styles.bold}>3.4 Verification: </span>Klub may, in connection with making available certain features of the Platform to You, require certain information or documents to verify details provided by You including but not limited to identity, constitutional information or authorization. In case You do not provide such information, You may be unable to access certain features of the Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>3.5 Patron Investor Reporting: </span>In the event, your interactions with Patron Investors on the Platform results in the successful completion of a Fundraise, You shall make sure that such reporting including such information, documents, business metrics and financial metrics as Klub may require, shall be shared through the Platform for reporting to Patron Investors who have concluded an investment in the Brand.
              </p>
            </div>

            {/* patron investor */}
            <h3 className={styles.headingH3}>4. Patron Investor</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>4.1 Sign-up: </span>In case You are a sophisticated Patron Investor interested in exploring opportunities to engage with early-stage companies and in the capacity of a Patron Investor, You have to register on the Platform as a ‘Patron Investor’ (<strong>Patron Investor</strong>). Once you register by providing your profile details, Klub may contact You before approving Your Patron Investor account on the Platform. You shall be eligible to use the features of the Platform available to a Patron Investor only after Your registration has been approved by Klub for the same. Depending on the information you provide such as interests and prior investments, the Platform will display Members of the Platform with whom You may wish to connect.
              </p>
              <br />
              <p>
                <span className={styles.bold}>4.2 Eligibility: </span>By applying for a Patron Investor account on the Platform, You confirm that You are a sophisticated Patron Investor with personal or professional experience assessing the short term and long term business prospects of early-stage companies and venture capital undertakings and understand the risk of investing in such businesses, including the high likelihood of loss and a long period of illiquidity. You further confirm that You have prior experience in investing in a start-up, emerging and early-stage ventures; or have promoted or co-promoted more than one start-up; or are senior management professional with at least 10 years of experience; and have net tangible assets of INR 2 crores excluding your principal residence. You acknowledge that you are capable of evaluating the merits and risks of such investments, are able to incur a complete loss of such investment without impairing your financial condition and are able to bear the economic risk of such investment for an indefinite period of time.
              </p>
              <br />
              <p>
                <span className={styles.bold}>4.3 Features: </span>We provide several tools and services for you to explore and evaluate Brands. We also display and classify Brands under several categories such as ‘Featured’, ‘Trending’ and ‘Recommended’. This classification is made by our software algorithms and offline analysts team based on your prior investments, sector interests, other information provided by You and interactions of other Members of the Platform with such Brand. We may not display all Brands that meet Your criteria or all Brands We display may not satisfy your criteria, but these classifications are made on a best efforts basis. We also provide features to follow a Brand’s profile and/or progress through different tools on the Platform. Some of these features are available based on whether You have previously invested or committed to invest in a particular Brand.
              </p>
              <br />
              <p>
                <span className={styles.bold}>4.4 Investment: </span> Based on Your review of the information provided by a Brand, You may choose to evince interest in investing in a Brand and reserve Your right to participate in an offer inviting investment by the Brand (<strong>Commit</strong>). Although several of the features and services We provide to You are free, to make a Commit You must be subscribed to our SEBI registered funding vehicle and also pay a one-time transaction fee for each commit. The Brand may, at its sole discretion, accept Your Commit and make an offer inviting Your investment and the terms and conditions of such investment shall be agreed between You and the Brand and Klub shall not be responsible for the same. You must obtain such professional advice as is appropriate to protect Your interests, including legal, tax, accounting, financial, due diligence and other advice. Klub may, at its sole discretion, deactivate or cancel Your account on the Platform in the event You do not invest in a Brand upon its issue of a duly executed offer letter after having made a Commit on the Platform and such event may be treated as a breach of these Terms of Use. Provided however that, this may not be construed a breach of these terms in case You have informed the Brand, with intimation to Klub, of Your discomfort with any material finding within 7 (seven) days of being intimidated of such finding and such issue has not been resolved to Your reasonable satisfaction.
              </p>
              <br />
              <p>
                <span className={styles.bold}>4.5 Investment Vehicles: </span>A Brand may require or provide an option for You to invest through certain investment vehicles such as alternative investment funds and special purpose vehicles. The terms and conditions of Your investment through such vehicle shall be governed by a separate agreement executed with You by such vehicle and/or the Brand.
              </p>
              <br />
              <p>
                <span className={styles.bold}>4.6 Investment Vehicles: </span>As a Patron Investor, You may also be allowed to create a profile on behalf of an entity such as investment funds, incubators, accelerators, portfolio manager, investment banks or family offices (<strong>Enterprise</strong>).You represent that you are duly authorized to create such profile and represent the Enterprise on the Platform and that your actions on the Platform shall be binding on the Enterprise.
              </p>
              <br />
              <p>
                <span className={styles.bold}>4.7 Risks: </span>
                You acknowledge that an investment in a single Brand or multiple Brands on the Platform involves a high degree of risk, regardless of whether such investment is direct or through an investment vehicle. There can be no assurance that
                <ol>
                  <li>A Brand will achieve its business plan, stated objectives or targeted valuation.</li>
                  <li>The investment vehicle’s objectives will be achieved in investing;</li>
                  <li>A Patron Investor will receive a return of any part of its investment. In addition, there may be occasions when the investment advisor to an investment vehicle (Manager), and/or their respective affiliates may encounter potential conflicts of interest in connection with an investment vehicle or Brand, such that said party may avoid a loss, or even realize a gain when other Patron Investors in the investment vehicle/Brand are suffering losses.</li>
                </ol>
                The following considerations, among others, should be carefully evaluated before making an investment in a Brand, whether directly or through an investment vehicle. A Brand is likely to operate in the market is highly competitive and the percentage of companies that survive and prosper is small. Early-stage businesses often experience unexpected problems in the areas of product development, manufacturing, marketing, financing, and general management, among others, which frequently cannot be solved. In addition, Brands may require substantial amounts of financing, which may not be available through institutional private placements, the public markets or otherwise. Please refer to Clause  <a href="https://klubworks.com/risks">https://klubworks.com/risks</a> before using the Platform.
              </p>
            </div>

            {/* patron investor */}
            <h3 className={styles.headingH3}>5. Patron Investor</h3>
            <div className={styles.sectionContent}>
              <p>You shall not host, display, upload, modify, publish, transmit, update or share any information that:</p>
              <br />
              <p>Belongs to another person and to which You do not have any right.</p>
              <br />

              <p> Is grossly harmful, harassing, libellous, invasive of another’s privacy, hateful or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, otherwise unlawful in any manner whatsoever.</p>
              <br />
              <p>Harms minors in any way.</p>
              <br />
              <p>Infringes any patent trademark, copyright or other proprietary rights.</p>
              <br />
              <p>Violates any law for the time being in force</p>
              <br />
              <p>Deceives or misleads the users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature.</p>
              <br />
              <p>Impersonates another person.</p>
              <br />
              <p>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with friendly states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</p>
              <br />
              <p>
                You understand that any content You find on or through the Platform is the sole responsibility of the person who originated such content. You confirm that You are not relying on Klub to and that You understand that We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy or reliability of any content or communications posted via the Platform or endorse any opinions expressed on the Platform. You understand that Your content may be republished and if You do not have the right to submit content for such use, it may subject You to liability, and that Klub will not be responsible or liable for any use of Your content by Klub in accordance with these Terms of Use.
              </p>
            </div>

            {/* chat */}
            <h3 className={styles.headingH3}>6.CHAT</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>6.1: </span>Please use the chat features of the Platform responsibly and in accordance with Paragraph 5.1 above. You shall not use the Platform to send unsolicited commercial messages (&apos;spam&apos;) or unsolicited mass distribution of files. In order to enforce this provision, Klub may establish additional policies regarding the use of chat features, including, but not limited to, maximum retention times of messages, the maximum number of messages sent per day, or a maximum size of a message.
              </p>
              <br />
              <p>
                <span className={styles.bold}>6.2: </span>You agree not to use or launch any automated system, including without limitation, “robots,” “spiders,” “offline readers,” etc., to collect or copy data from the Platform. You further agree that You shall access the Platform only via the website through a web browser or a mobile user interface. Notwithstanding the foregoing, Klub grants the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials. Klub reserves the right to revoke these exceptions either generally or in specific cases. You agree not to collect or harvest any personally identifiable information, including account names, from the Platform, nor to use the communication systems provided by the Platform for any commercial solicitation purposes.
              </p>
            </div>

            {/* representation and warranties of user */}
            <h3 className={styles.headingH3}>7. Representations and Warranties of User</h3>
            <div className={styles.sectionContent}>
              <p>
                You hereby represent and warrant:
              </p>
              <p>
                <span className={styles.bold}>7.1.1: </span>That You have the right, authority, and capacity to enter into this Terms of Use on Your own behalf and on behalf on any entity for whom You are acting and to abide by all of the terms and conditions contained herein, and that if any aspect of Your participation violates provisions of the law to which You are subject, You will cease using the services and close your account
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.2: </span>That You are at least 18 years old and competent to execute and perform into any agreement you enter into through Our Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.3: </span>That You shall not use a false name or email address owned or controlled by another person with the intent to impersonate that person or for any other reason.
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.4: </span> That You shall not use a user ID name that is subject to any rights of a person other than yourself without appropriate authorization.
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.5: </span>That You shall comply with the terms and conditions stipulated in this Terms of Use.
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.6: </span> That You shall be solely responsible for maintaining the confidentiality of Your password.
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.7: </span> That all the representations made by You to Us including in accordance with these terms and conditions are true, correct and complete and that You will update your registration information with the Company as needed so that it remains true, correct and complete.
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.8: </span>That You will conduct yourself in a professional manner in all your interactions with any other user, Klub, their respective directors, employees and officers and shall not defame, harass, intimidate, threaten, disparage, libel or otherwise make a false statement concerning any of the aforesaid persons in the course of your interactions with them or third parties.
              </p>
              <br />
              <p>
                <span className={styles.bold}>7.1.9: </span> That Your usage of the Platform will not, in any manner, directly or indirectly, violate terms of any contractual arrangement binding on You. Further, You agree not to use the Platform in a manner that results in an event where You may experience a conflict of interest such as with an entity in which
              </p>
              <br />
              <p>You are currently holding shares;</p>
              <br />
              <p>You are a director or a key employee</p>
              <br />
              <p>You exercise significant influence.</p>
              <br />
              <p>
                <span className={styles.bold}>7.1.10: </span>That You shall cooperate in good faith with other users, fellow shareholders in the Brand, Klub and its personnel, and their respective employees and officers to give effect to and honour the terms of any contract entered into between You and such persons, whether or not executed through the Platform.
              </p>
            </div>

            {/* emails,newsletters and notifications */}
            <h3 className={styles.headingH3}>8. Emails, Newsletters and Notifications</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>8.1: </span>If You wish to subscribe to Our newsletter(s) or receive notifications or emails from Us, We will use Your name and latest e-mail address provided by You in this regard. However, You may choose to stop receiving Our newsletters by following the instructions to unsubscribe as included in these emails or You can contact Us at <a className={styles.supportLink} href="mailto:support@klubworks.com">support@klubworks.com</a>.
              </p>
            </div>

            {/* intellectual property */}
            <h3 className={styles.headingH3}>9. Intellectual Property</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>9.1: </span>This Platform is operated by and is the sole property of Klub. Any and all material on this Platform, including images, illustrations, audio and video clips, trade names, proprietary information and knowledge, technology, databases, or rights with respect thereto necessary for Our business as is now being operated are protected by copyrights, trademarks, and other intellectual property rights that are owned by Us or by other parties that have licensed such material to Us, or such parties having ownership of the content uploaded on the Platform. You agree that any and all material displayed on the Platform is solely for Your personal use and You shall not, whether directly or indirectly, copy, reproduce, republish, post, upload, transmit or distribute such material in any manner and through any media including by way of e-mail or other electronic means and You shall not assist any other person in doing so. Modification of the said materials or use of the materials on any other Platform or networked computer environment or use of the materials for any purpose other than personal use is a violation of the said copyrights, trademarks and other intellectual proprietary rights, and is expressly prohibited. Klub grants to You a temporary, non-exclusive, revocable, non-transferable limited license to use the Platform for your own use. All right, title and interest in and to the Platform (excluding content uploaded specifically by a user) are and will remain the exclusive property of Klub and/or its licensors.
              </p>
              <br />
              <p>
                <span className={styles.bold}>9.2: </span>You agree that these Terms of Use do not entitle You to any support, upgrades, updates, add-ons, patches, enhancements, or fixes for the Platform (Updates). We may, however, occasionally provide automatic Updates to the Platform at its sole discretion (and without any advanced notification to You). Any such Updates shall become part of the services and subject to these Terms of Use.
              </p>
              <br />
              <p>
                <span className={styles.bold}>9.3: </span>From time to time, we test various aspects of our Platform, including our website, user interfaces, service levels, plans, promotions, features, availability of content, delivery, and pricing, and we reserve the right to include you in or exclude you from these tests without notice.
              </p>
              <br />
              <p>
                <span className={styles.bold}>9.4: </span> By submitting any content or material to the Platform including names, logos and/or any material that is protected through trademark, copyright, wordmark, etc. of Yourself or Your Enterprise or Your Brand, You hereby grants Klub a worldwide, non-exclusive, royalty-free, perpetual, sub-licensable and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform such content in connection with the Platform and Klub’s business, including without limitation for promoting and redistributing part or all of the Platform (and derivative works thereof) in any media formats and through any media channels. In the event it does so, Klub will seek to maintain the confidentiality of the content consistent with Your account classification, but once again cannot guarantee any such confidentiality. You also grant each user of the Platform a non-exclusive license to access Your Content through the Platform and to use, reproduce, distribute, prepare derivative works of, display and perform such Content as permitted through the functionality of the Platform and under these Terms of Use.
              </p>
              <br />
              <p>
                <span className={styles.bold}>9.5: </span> Klub shall have no obligation to monitor or enforce any intellectual property rights that may be associated with the content provided by You on the Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>9.6: </span> If You are a copyright owner or an agent thereof and believe that any content on the Platform infringes upon Your rights, You may contact <a className={styles.supportLink} href="mailto:support@klubworks.com">support@klubworks.com</a> and provide details requested in regards to such infringement.
              </p>
            </div>

            {/* prohibited uses */}
            <h3 className={styles.headingH3}>10. Prohibited Uses </h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>10.1: </span>The Platform may be used only for lawful purposes. Klub specifically prohibits any use of the Platform for and You agree not to use the Platform for any of the following purposes:
              </p>
              <br />
              <p>
                <span className={styles.bold}>10.1.1: </span>Modifying, publishing, storing, transmitting, distributing, displaying, performing, participating in the transfer or sale of, create derivative works on, or in any way exploiting, any of the copyrighted material contained on the Platform, in whole or in part, without Our prior written consent.
              </p>
              <br />
              <p>
                <span className={styles.bold}>10.1.2: </span>Subleasing, leasing, selling, assigning, transferring, distributing, renting, permitting concurrent use of, or granting other rights in the content and any material and documentation provided on the Platform by other users or Klub to any third party.
              </p>
              <br />
              <p>
                <span className={styles.bold}>10.1.3: </span> Providing use of the content in a computer service business, network, time sharing, interactive cable television, multiple CPU or multiple or concurrent user arrangement to users who are not individually licensed by Klub or otherwise exploiting any portion of, the use of or access to the content of the materials or documentation provided on the Platform in contravention of these Terms of Use.
              </p>
              <br />
              <p>
                <span className={styles.bold}>10.1.4: </span>Defame, libel, disparage, threaten, harass or intimidate anyone or otherwise violate the personal, privacy, contractual, intellectual property or other rights of any person, including by the use of offensive comments related to race, national origin, gender, sexual preference or physical handicap
              </p>
              <br />
              <p>
                <span className={styles.bold}>10.1.5: </span>Submit any content or material that falsely expresses or implies that such content or material is sponsored or endorsed by Klub.
              </p>
              <br />
              <p>
                <span className={styles.bold}>10.1.6: </span>sell access to the Platform or any part thereof other than through a mechanism expressly approved by Klub.
              </p>
              <br />
              <p>
                <span className={styles.bold}>10.2: </span> Klub may also place additional fair use restrictions including restrictions on concurrent connections and time of usage.
              </p>
            </div>

            {/* modifications */}
            <h3 className={styles.headingH3}>11. Modifications</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>11.1: </span>We reserve the right at any time and from time to time, in Our sole discretion, to modify the content on the Platform or any part thereof, including the transmission of any related materials or documentation, with or without providing prior notice to You. We further reserve the right at any time and from time to time, at Our sole discretion, to alter, modify or terminate any content or features contained on the Platform without providing to You prior notice of such alteration, modification or termination. You agree that Klub shall not be liable to You or to any third-party claiming through You, for any modification of the content or features provided on the Platform.
              </p>
            </div>

            {/* system limitations and failures */}
            <h3 className={styles.headingH3}>12. System Limitations and Failures </h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>12.1: </span>We use internally developed systems for providing You access to and facilitating Your use of the Platform. These systems may encounter technical or other limitations, and computer and communications hardware systems might experience interruptions. Further, We continually enhance and improve these systems in order to accommodate the level of use of the Platform. We may also add additional features and functionality to Platform that might result in the need to develop or license additional technologies. Increased utilization of the Platform or providing new features or functionality may cause unanticipated system disruptions, slower response times, degradation in levels of customer service, and delays in reporting accurate financial information. You agree that We shall not be liable to You or to any third-party claiming through You, for any such failures contemplated herein.
              </p>
            </div>

            {/* privacy and confidentiality */}
            <h3 className={styles.headingH3}>13. Privacy and Confidentiality</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>13.1: </span> Your use of the Platform is governed by Our privacy policy, which can be viewed at <a href="https://klubworks.com/privacy-policy">https://klubworks.com/privacy-policy.</a>
              </p>
              <br />
              <p>
                <span className={styles.bold}>13.2: </span>Please read Our privacy policy to understand Our information collection and usage practices. You may obtain certain confidential information, including without limitation, technical, contractual, product, pricing, business-related functions, activities and services, customer lists, knowledge of customer needs and preferences, business strategies, marketing strategies, methods of operation, markets and other valuable information that should reasonably be understood as confidential (&quot;Confidential Information&quot;). You acknowledge and agree to hold all Confidential Information in strict confidence. Title and all interests to all Confidential Information shall be vested in Us. Your obligations regarding Confidential Information will survive the termination of these Terms of Use in accordance with Paragraph 18below. Upon such termination, You must stop forthwith using any Confidential Information to which You may have been exposed in due course of Your use of the Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>13.3: </span>You agree that Your obligations under this Paragraph13 are necessary and reasonable in order to protect Our business and expressly agree that monetary damages would be inadequate to compensate for any breach of any covenant or agreement set forth herein. Accordingly, You agree and acknowledge that any such violation or threatened violation will cause irreparable harm and injury to Klub and that, in addition to any other remedies that may be available, in law, equity or otherwise, Klub shall be entitled to obtain injunctive relief against the threatened breach of the terms of this Paragraph or the continuation of any such breach.
              </p>
              <br />
              <p>
                <span className={styles.bold}>13.4: </span> The restrictions in this Paragraph 13 shall not apply to the disclosure of Confidential Information by either party if and to the extent the disclosure is:
              </p>
              <br />
              <p>
                Required by the Applicable Law of any jurisdiction
              </p>
              <br />
              <p>
                required by any applicable securities exchange, supervisory or regulatory or governmental body to which the relevant party is subject or submits, wherever situated, whether or not the requirement for disclosure has the force of law
              </p>
              <br />
              <p>
                made to employees and representatives on a need to know basis, provided that such persons are required to treat such information as confidential through a written agreement in terms which are no less strict than this Paragraph
              </p>
              <br />
              <p>
                Provided that restriction in this Paragraph 13 shall not apply to disclosures of Confidential Information made by Klub to its shareholders, directors, managers, advisors, potential Patron Investors and/or Affiliates. Provided further that Klub may disclose the Confidential Information to third party vendors and customers or pursuant to partnering arrangements without the restriction of this Paragraph 13 provided that such persons are required to treat such information as confidential through a written agreement in terms which are no less strict than this Paragraph
              </p>
            </div>

            {/* additional terms and conditions */}
            <h3 className={styles.headingH3}>14. Additional / Special Terms and Conditions </h3>
            <p className={styles.sectionContent}>
              <span className={styles.bold}>14.1: </span> Klub may notify you or request you to accept additional or special terms and conditions in relation to Your access of certain features or services on the Platform. You agree that You shall access or use such features and services only in accordance with such additional or special terms and conditions as if they were incorporated herein these Terms of Use.
            </p>

            {/* links from other platforms and thhird party rules */}
            <h3 className={styles.headingH3}>15. Links to / from other Platforms and Third Party Tools </h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>15.1: </span>The Platform may contain link/s to various other websites. These links are provided solely for Your convenience and benefit. Wherever such link/s lead to websites which are not owned by or belong to Klub, We shall not be responsible for the content, products and services provided on such linked sites. We do not warrant or make any representations regarding the correctness or accuracy of the content on such websites. If You decide to access such linked websites, You do so at Your own risk. We do not in any way endorse the linked websites.
              </p>
              <br />
              <p>
                <span className={styles.bold}>15.2: </span>Similarly, this Platform can be made accessible through a link created by other websites. Access to this Platform through such link/s shall not mean or be deemed to mean that the objectives, aims, purposes, ideas, concepts of such other websites or their aim or purpose in establishing such link/s to this Platform are necessarily the same or similar to the idea, concept, aim or purpose of Our Platform or that such links have been authorized by Us. We are not responsible for any representation/s of such other websites while affording such link and no liability can arise upon Klub consequent to such representation, its correctness or accuracy. In the event that any link/s afforded by any other website/s derogatory in nature to the objectives, aims, purposes, ideas and concepts of this Platform, is utilized to visit this Platform and such event is brought to the notice or is within the knowledge of Klub, civil or criminal remedies as may be appropriate shall be invoked.
              </p>
              <br />
              <p>
                <span className={styles.bold}>15.3: </span>Certain Members can connect their account on the Platform to other platforms such as LinkedIn or any other third party websites. This feature requires you to accept and comply with the terms of service and privacy policy of such third party websites. If you choose to connect, you will be able to take advantage of various social features included as part of the Platform specifically for the features available on such third party websites. In addition, Klub may personalize and otherwise enhance your experience based on your information obtained from or through such third party websites. By connecting your Klub account to your LinkedIn account or any other third party accounts, you acknowledge and agree that you are consenting to the continuous release of information about you to others, including to LinkedIn or any other third party (in accordance with your privacy settings on such third party and these Terms of Use). If you do not want information about you to be shared in this manner, do not use the connect feature of LinkedIn or any other third party website. We disclaim all warranties in relation to and all liabilities arising from any use of your personal information by LinkedIn or any other third party website.
              </p>
            </div>

            {/* security */}
            <h3 className={styles.headingH3}>16. SECURITY</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>16.1: </span>You are prohibited from violating or attempting to violate the security of the Platform, including, without limitation:
              </p>
              <br />
              <p>1. accessing data not intended for You or logging into an account which You are not authorized to access </p>
              <p>2. attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization</p>
              <p>3. interfere with service to any user, host, or network. </p>
              <br />
              <p>
                <span className={styles.bold}>16.2: </span>You shall not misuse this Platform by knowingly introducing viruses, trojans, worms, logic bombs or other materials that are malicious or technologically harmful (together <strong>&quot;Viruses&quot;</strong>). You must not attempt to gain unauthorized access to our Platform, the server on which the Platform is stored or any server, computer or database connected to this Platform. You must not attack this Platform via a denial-of-service attack. Further, You shall not interfere with or circumvent any security feature of the Platform or any feature that restricts or enforces limitations on use of or access to the Platform, such as probing or scanning the vulnerability of any system, network or breach.
              </p>
              <br />
              <p>
                <span className={styles.bold}>16.3: </span>By breaching the provisions of this Paragraph 16, You may be liable to be prosecuted under the Information Technology Act, 2000 and any other applicable law. We will report any such breach to the relevant law enforcement authorities and We will cooperate with such authorities by disclosing Your identity to them. In the event of such a breach, Your rights to use this Platform will cease immediately.
              </p>
              <br />
              <p>
                <span className={styles.bold}>16.4: </span>We will not be liable for any loss or damage caused by a denial-of-service attack or Viruses that may infect your computer equipment, computer programs, data or other proprietary material due to Your use of this Platform or to Your downloading of any material posted on it, or on any Platform linked to it.
              </p>
              <br />
              <p>
                <span className={styles.bold}>16.5: </span>You agree to immediately report to Us all incidents involving suspected or actual unauthorized access, disclosure, alteration, loss, damage, or destruction of data.
              </p>
              <br />
              <p>
                <span className={styles.bold}>16.6: </span>You shall not interfere with or disrupt (or attempt to interfere with or disrupt) access and enjoyment of the Platform of other users or any host or network, including, without limitation, creating or transmitting unwanted electronic communications such as “spam” to other users, overloading, flooding or mail-bombing the Platform, or scripting the creation of content in such a manner as to interfere with or create an undue burden on the Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>16.7: </span> You are prohibited from reverse engineering, decompiling, reverse assembling, modifying or attempting to discover or copy any software, source code or structure that the Platform utilizes to generate web pages or any software or other products or processes accessible through the Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>16.8: </span>All actions performed by any person using Your account and password shall be deemed to have been committed by You and You shall be liable for the same. Klub reserves the right to suspend/terminate Your account at any time if it is found that You have been sharing the password with any unauthorized user.
              </p>
              <br />
              <p>
                <span className={styles.bold}>16.9: </span> In order to reduce the risk of unauthorized access, all accounts maintained with Us through the Platform are locked after five (5) consecutive incorrect login attempts. In the event of such lockout, You can email the administrator at <a href="mailto:support@klubworks.com">support@klubworks.com</a> and the account shall be unlocked upon receipt of Your email. You can also request for a password reset on our website through the ‘forgot password’ option on Our Platform.
              </p>
            </div>

            {/* monitoring */}
            <h3 className={styles.headingH3}>17. MONITORING</h3>
            <p className={styles.sectionContent}>
              <span className={styles.bold}>17.1: </span>All electronic communications and content presented and/or passed to Us, including that presented and/or passed from remote access connections, may be monitored, examined, saved, read, transcribed, stored, or retransmitted in the course of daily operations by any duly authorized employee or agent of Klub in the exercise of their duties, or by law enforcement authorities who may be assisting Klub in investigating possible contravention / non-compliance with applicable law. Electronic communications and content may be examined by automated means. Further, Klub has the right to reject, at its sole discretion, from the Platform any electronic communications or content deemed not to be in compliance with the corporate policies and procedures of Klub.
            </p>

            {/* termination */}
            <h3 className={styles.headingH3}>18. TERMINATION</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>18.1: </span> In the event it is determined by Us that You have violated any of these Terms of Use, We shall have the right, at Our sole discretion, to suspend Your use of and prohibit access to any or all features/parts of the Platform forthwith. Any such suspension or termination of access to Platform may be effected by Us without providing You with prior written notice in this regard. Upon such termination of access to the Platform, these Terms of Use, as applicable to You, will be deemed to have been terminated and no refund of any payments made by You shall be required to be made by Klub.
              </p>
              <br />
              <p>
                <span className={styles.bold}>18.2: </span>You acknowledge and agree that, upon termination, You shall immediately destroy any copies made of any portion of the content contained on the Platform. You acknowledge and agree that Klub shall not be liable to You or any third party claiming through You, for any suspension or termination of access to Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>18.3: </span>These Terms of Use shall stand terminated upon Your closing of Your account on the Platform.
              </p>
              <br />
              <p>
                <span className={styles.bold}>18.4: </span>The rights and obligations of the Parties under this Agreement, which either expressly or by their nature survive the termination of these Terms of Use including but not limited to Paragraph 9 (Intellectual Property), Paragraph 13 (Privacy and Confidentiality), Paragraph 20(Indemnity), Paragraph 22 (Governing Law and Dispute Resolution), Paragraph 25.3 (Notice) and Paragraph18.2 shall survive the termination of this Agreement.
              </p>
              <br />
              <p>
                <span className={styles.bold}>18.5: </span>Except as otherwise specifically provided herein, the termination of these Terms of Use for any reason whatsoever shall be without prejudice to any rights or obligations accrued to or in respect of the parties prior to the date of termination.
              </p>
            </div>

            {/* disclaimer of warranties and limitation of liability */}
            <h3 className={styles.headingH3}>19. Disclaimer of Warranties and Limitation of Liability </h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>19.1: </span>Klub does not endorse or act on behalf of any third party. In the event You engage the services of / to interact with any third party, through the Platform, Klub will not be liable to You for any act or omission by such third party in relation thereto.
              </p>
              <br />
              <p>
                <span className={styles.bold}>19.2: </span>We hereby expressly disclaim all warranties and representations of any kind with respect to any and all content and features available on the Platform, including but not limited to
              </p>
              <br />
              <p>warranties as to the merchantability or use for a particular purpose whether or not Klub knows or has reason to know or has been advised of any such purpose. OR </p>
              <br />
              <p>warranties as to any results to be obtained from any use of the Klub content or information derived from the use of the Platform.</p>
              <br />
              <p>
                <span className={styles.bold}>19.3: </span>Klub shall have no liability for any losses, direct or indirect, in contract, tort, or otherwise, incurred in connection with the Klub content on the Platform, including but not limited to loss of revenue or profit or any other commercial or economic loss or for any decision made or action taken by any third party in reliance upon the Klub content.
              </p>
              <br />
              <p>
                <span className={styles.bold}>19.4: </span>Any content or services provided through the Platform shall not be construed as investment advice by Klub or opinion provided by Klub regarding the appropriateness or suitability of any investment, or a recommendation or an offer or solicitation by Klub or the new and growing businesses for the purchase or sale of any security or securities in general, nor otherwise an endorsement, inducement, or solicitation of any type.
              </p>
              <br />
              <p>
                <span className={styles.bold}>19.5: </span>You acknowledge and agree that by the mere fact of registering on Our Platform, You do not acquire any right to subscribe to the shares of any of the said businesses. Such right to subscribe to the shares of any of the said businesses shall be conferred upon You only in the event that such business or entity extends an express invitation or offer to You to subscribe to the shares of such business or entity.
              </p>
              <br />
              <p>
                <span className={styles.bold}>19.6: </span> The Platform, all the materials and services, included on or otherwise made available to You through this Platform is provided by Klub on an &quot;as is&quot; and &quot;as available&quot; basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the foregoing paragraph, Klub does not warrant that:
              </p>
              <br />
              <p> The Platform will be constantly available, or available at all</p>
              <br />
              <p> The information on the Platform or provided through the Website is complete, true, accurate or not misleading</p>
              <br />
              <p> The quality of any products, services, information, or other material that you obtain through the Platform will meet your expectations</p>
              <br />
              <p>
                <span className={styles.bold}>19.7: </span>Klub is NOT a stock exchange recognised by the Securities Exchange Board of India (SEBI) under the Securities Contract (Regulation) Act, 1956. The securities offered by any company registered on klubworks.com (&quot;Platform/App&quot;) are not traded on any stock exchange recognised by SEBI. The Platform only registers the eligible investors and companies and allows them to interact with each other in a restricted environment. To comply with the provisions on private placement under the Companies Act, 2013 (the Act), Klub follows the following practice: Owing to the restrictions prescribed by under the Companies Act 2013, a company can&#39;t solicit investments from more than 200 investors at any given point in time. In light of these restrictions, we ensure that any startup registered on the platform is not viewed by more than 200 investors.
              </p>
            </div>

            {/* indemnity */}
            <h3 className={styles.headingH3}>20. Indemnity </h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>20.1: </span> You agree to indemnify and hold Klub harmless from:
              </p>
              <br />
              <p> any actions, claims, demands, suits, damages, losses, penalties, interest and other charges and expenses (including legal fees and other dispute resolution costs) made by any third party due to or arising out of Your use of the Platform, any violation of the terms of these Terms of Use or any other agreement executed with Klub or another user by You</p>
              <br />
              <p> any acts or deeds, including for any non-compliance or violation, of any applicable law, rules, regulations on Your part </p>
              <br />
              <p>for fraud committed by You</p>
              <br />
              <p>or Your infringement of any intellectual property or other rights of any person or entity.</p>
              <br />
              <p>or as a result of any threatening, libellous, obscene, harassing or offensive material posted/transmitted by You on the Platform. </p>
              <p>
                <span className={styles.bold}>20.2: </span>Your obligations under this Paragraph 20 shall survive the termination of these Terms of Use in accordance with Paragraph 18 above.
              </p>
            </div>

            {/* foreign jurisdiction */}
            <h3 className={styles.headingH3}>21. Foreign Jurisdictions </h3>
            <p className={styles.sectionContent}>
              <span className={styles.bold}>21.1: </span> Klub makes no representation that the content contained on the Platform is appropriate or to be used or accessed outside of the Republic of India. You are expressly notified that many states and foreign countries all have their own regulations that must be observed in relation to Your use or access of the Platform. You must make your own assessment regarding, and are responsible for compliance with, regulatory requirements as may be applicable to Your use and access of the Platform in such jurisdictions.
            </p>

            {/* governing law and dispute resolution */}
            <h3 className={styles.headingH3}>22.Governing Law and Dispute Resolution</h3>
            <div className={styles.sectionContent}>
              <p>
                <span className={styles.bold}>22.1: </span>These Terms of Use shall be governed by and construed in accordance with the laws of the Republic of India and subject to the provisions of arbitration set out herein, the courts at Bangalore, India shall have exclusive jurisdiction in relation to any Disputes (defined below) arising out of or in connection with these Terms of Use subject to the provisions of this Paragraph 22.
              </p>
              <br />
              <p>
                <span className={styles.bold}>22.2: </span> Any action, dispute or difference arising under or relating to this Terms of Use (<strong>Dispute</strong>) shall at the first instance be resolved through good faith negotiations between the parties hereto, which negotiations shall begin promptly, within 15 (fifteen) days after a party has delivered to the other party a written request for such consultation. If the parties are unable to resolve the Dispute in question within 15 (fifteen) days of the commencement of negotiations, such Dispute shall be referred to and finally resolved by arbitration in accordance with the Arbitration and Conciliation Act, 1996, as amended from time to time and rules prescribed thereunder. When any Dispute is under arbitration, except for the matters under dispute, Klub and You shall continue to exercise the remaining respective rights and fulfil the remaining respective obligations under this Terms of Use.
              </p>
              <br />
              <p>
                <span className={styles.bold}>22.3: </span>The arbitration shall be conducted by a sole arbitrator jointly appointed by Klub and You. If parties fail to appoint an arbitrator within 30 days after service of the notice of arbitration, such arbitrator shall be appointed in accordance with provisions of the Arbitration and Conciliation Act, 1996. The venue of arbitration shall be Bangalore, India.
              </p>
              <br />
              <p>
                <span className={styles.bold}>22.4: </span>The language of the arbitration proceedings and of all written decisions and correspondence relating to the arbitration shall be English.
              </p>
              <br />
              <p>
                <span className={styles.bold}>22.5: </span> You agree and acknowledge that the provisions of Paragraph9 (Intellectual Property) and Paragraph 13 (Privacy and Confidentiality) are of importance to Klub and monetary compensation may not constitute adequate relief and remedy to Klub for non-performance by You of your obligations thereunder. Accordingly, Klub shall be entitled to claim specific performance and seek mandatory and/or perpetual injunctions requiring You to perform your duties and obligations under such provisions.
              </p>

              {/* severability */}
              <h3 className={styles.headingH3}>23. Severability </h3>
              <p className={styles.sectionContent}>
                <span className={styles.bold}>23.1: </span>If any term, provision, covenant or restriction of these Terms of Use is held by a court of competent jurisdiction to be invalid, void or unenforceable, the remainder of the terms, provisions, covenants and restrictions of these Terms of Use shall remain in full force and effect and shall in no way be affected, impaired or invalidated.
              </p>

              {/* no waiver */}
              <h3 className={styles.headingH3}>24 No Waiver </h3>
              <p className={styles.sectionContent}>
                <span className={styles.bold}>24.1: </span> The rights and remedies available under this Terms of Use may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.
              </p>

              {/* general */}
              <h3 className={styles.headingH3}>25. General </h3>
              <div className={styles.sectionContent}>
                <p>
                  <span className={styles.bold}>25.1: </span>These Terms of Use comprise the full and complete agreement between You and Klub with respect to the use of the Platform and supersedes and cancels all prior communications, understandings and agreements between You and Klub, whether written or oral, expressed or implied with respect thereto.
                </p>
                <br />
                <p>
                  <span className={styles.bold}>25.2: </span>Klub shall be entitled to assign their rights and obligations hereunder to any Affiliate, third party or as part of any restructuring, business combination, merger or acquisition.
                </p>
                <br />
                <p>
                  <span className={styles.bold}>25.3: </span> Any notice to be given in connection with these Terms of Use shall be delivered via email to Klub at <a href="mailto:support@klubworks.com">support@klubworks.com</a> or to You at the email address provided by you in Your profile. You consent to the use of electronic communications in order to the electronic delivery of notices in relation to any matter under these Terms of Use.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default TermsAndConditions;
